<template>
    <div class="app-container">
        <el-row :gutter="30" v-if='paperList.length>0'>
            <el-col :sm='{span:12}' :lg='{span:6}' :md='{span:8}' v-for="(item,index) in paperList" :key="item.paperId"
                style="margin-top:5px;margin-bottom:2vh">
                <paper-cover :title="item.title" :endTime="item.endTime" :institution="item.institution"
                    :reward="item.reward" :paperId="item.paperId" :reviewId="item.reviewId" :isRe='item.isRe' :allowSave='item.allowSave'
                    :paperUrl='item.paperUrl' :paperType="item.type" :annexUrl="item.annexUrl">
                </paper-cover>
            </el-col>
        </el-row>
        <div class="notPaper" v-else>
            <!-- 暂无待评审论文 -->
            <el-empty description="暂无待评审论文"></el-empty>
        </div>
        <change-info :expertId='expertId'></change-info>
    </div>
</template>

<script>
    import paperCover from '@/components/unfinished/paperCover'
    import changeInfo from '@/components/personalInfo/changeInfo'
    export default {
        name: "unfinishedList",
        data() {
            return {
                paperList: [
                ],
                expertId: '',
                changeDiaVis: false
            }
        },
        created() {
            // this.expertId = sessionStorage.getItem("expertId");
            //获取待评审列表
            // this.$store.dispatch("changeExpertId", this.expertId);
            this.getPaperList();
            this.checkInfo();
        },
        methods: {
            getPaperList() {
                this.$api.unfinished.getReviewingPaperList()
                    .then(res => {
                        if (res.data.code == 200) {
                          console.log(res.data.data)
                            this.paperList = res.data.data;
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            checkInfo() {
                if (this.$store.state.professor.isLogin == 1) return;
                this.$store.dispatch('professor/changeIsLogin', 1);
                this.$api.info.getCheckInfo().then(res => {
                        if (res.data.code == 1) {
                            this.$store.dispatch('professor/changeIsChangeDiaVis', true);
                        } else {
                            this.$store.dispatch('professor/changeIsChangeDiaVis', false);
                        }
                    })
                    .catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
        },
        components: {
            "paper-cover": paperCover,
            'change-info': changeInfo,
        }
    }
</script>

<style scoped>
    .unfinishedMain {
        margin-right: 1rem;
    }

    .notPaper {
        text-align: center;
        font-size: 24px;
        color: rgb(77, 86, 95);
    }
</style>