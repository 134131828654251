<template>
    <el-card class="box-card" style="height:41vh;">
        <div slot="header" class="clearfix">
            <span>截止时间：{{endTime.substr(0,10)}}</span>
            <!-- <a :href="baseUrl+'/pro/downloadPaper?paperId='+paperId
            +'&isRe='+isRe" download='123.pdf' target="_blank" style="float:right; padding: 3px 0;font-size:1.5vh;"
                disabled="false">下载论文</a> -->
                <el-button type="text" style="float:right; padding: 3px 0;font-size:1.5vh;"
                :disabled="!allowSave" @click="downloadFile">下载论文</el-button>
            <!-- <el-link href="javascript:void 0;" style="float:right; padding: 3px 0;font-size:1.5vh;" type="primary"
                :disabled="allowSave == '1'" @click="downloadFile">下载论文
            </el-link> -->
            <!-- <el-button style="float:right; padding: 3px 0;font-size:1.5vh;" @click="download(paperUrl)" type="text">下载论文
            </el-button> -->
        </div>
        <div class='paperType one_tooltip' :title="paperType">
            {{paperType}}
        </div>
        <div class="paperInfo">
            <div>
                <el-row>
                    <el-col :span="8" style="text-align:center;">论文名称：</el-col>
                    <el-col :span="16" class="two_tooltip" :title="title">{{title}}</el-col>
                </el-row>
            </div>
            <div>
                <el-row>
                    <el-col :span="8" style="text-align:center;">送审单位：</el-col>
                    <el-col :span="16" class="one_tooltip" :title="institution">{{institution}}</el-col>
                </el-row>
            </div>
            <div>
                <el-row>
                    <el-col :span="8" style="text-align:center;">评审费用：</el-col>
                    <el-col :span="16">{{reward}} 元</el-col>
                </el-row>
            </div>
            <div v-if="annexUrl!=null">
                <el-row>
                    <el-col :span="8" style="text-align:center;">论文附件：</el-col>
                    <el-col :span="16" style="display: flex" >
                        <el-link @click="downloadAnnex(row)"  >
                          {{annexUrl}}
                        </el-link>
                        <img @click="downloadAnnex(row)" width="20px" height="20px" style="margin-left: 5px;margin-bottom: 0px !important;" src="./img/zip.png"/>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="paperCoverBtn">
            <el-link type="primary" style="font-size:2.5vh;" @click="handleStartReview(paperId,reviewId)">开始评审</el-link>
        </div>
    </el-card>

</template>

<script>
    export default {
        name: 'paperCover',
        data() {
            return {
                expertId: '',
                blob: '',
                baseUrl: ''
            }
        },
        props: ["paperType", "title", "endTime", "institution", "reward", "paperId", 'isRe', 'allowSave', 'paperUrl',
            'reviewId','annexUrl'
        ],
        created() {
            // this.baseUrl = this.$axios.defaults.baseURL;
            this.expertId = sessionStorage.getItem('expertId');
        },
        methods: {
            //下载
            downloadExportFile(blob, tagFileName, fileType) {
                let downloadElement = document.createElement('a');
                let href = blob;
                if (typeof blob == 'string') {
                    downloadElement.target = '_blank';
                } else {
                    href = window.URL.createObjectURL(blob); //创建下载的链接
                }
                downloadElement.href = href;
                downloadElement.download = tagFileName + '.' + fileType; //下载后文件名
                document.body.appendChild(downloadElement);
                downloadElement.click(); //点击下载
                document.body.removeChild(downloadElement); //下载完成移除元素
                if (typeof blob != 'string') {
                    window.URL.revokeObjectURL(href); //释放掉blob对象
                }

            },
            download(url) {
                let param = new URLSearchParams();
                param.append("paperId", this.paperId);
                param.append('isRe', this.isRe);
                this.$axios.get("pro/downloadPaper", {
                    params: param,
                    // headers:{'Content-Type':'application/pdf'},
                    responseType: 'blob'
                }).then(res => {

                }).catch(err => {
                    this.$message.warning("服务器维护！");
                });
                //this.downloadExportFile(url,'123','pdf')
                // let xhr = new XMLHttpRequest();
                // xhr.open('get', 'http://localhost:8080/static/123.pdf', true);
                // xhr.setRequestHeader('Content-Type', 'application/pdf');
                // xhr.responseType = "blob";
                // let that = this;
                // xhr.onload = function () {
                //     if (this.status == 200) {
                //         let blob = this.response;
                //         that.downloadExportFile(blob,'123','pdf')
                //         console.log(that.blob,'123','pdf');
                //     }
                // }
                // xhr.send();
                // console.log(this.blob)
            },
            downloadFile() {
                const loading = this.$loading({
                    lock: true,
                    text: '下载中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.$api.unfinished.downloadFile({
                    path:this.paperUrl
                }).then(res => {
                    const blob = new Blob([res.data], {
                        type: 'application/pdf;charset=utf-8'
                    });
                    let url = window.URL || window.webkitURL;
                    const href = url.createObjectURL(blob);
                    let downloadElement = document.createElement('a');
                    downloadElement.href = href;
                    downloadElement.download = this.getFileName(this.paperUrl); //下载后文件名
                    document.body.appendChild(downloadElement);
                    downloadElement.click(); //点击下载
                    document.body.removeChild(downloadElement); //下载完成移除元素
                    window.URL.revokeObjectURL(href); //释放掉blob对象
                    loading.close();
                }).catch()
            },
            getFileName(str) {
                return str.substr(str.lastIndexOf('\\') + 1)
            },
            //进入评审页面
            handleStartReview(paperId, reviewId) {
                // 获取用户信息
                this.$store.dispatch('reviewPaper/changePaperInfo',{name:this.title,type:this.paperType})
                this.$router.push({
                    name: 'reviewPage',
                    params: {
                        paperId: paperId,
                        reviewId: reviewId
                    }
                });
                // this.validateRevoke(paperId, expertId);
            },
            validateRevoke(paperId, expertId) {
                let param = new URLSearchParams();
                param.append('paperId', paperId);
                param.append('expertId', expertId);
                param.append('isRe', this.isRe);
                this.$axios.get('/pro/validatePaper', {
                    params: param
                }).then(res => {
                    if (res.data.code == 0) {
                        this.$store.dispatch('changePaperId', paperId);
                        this.$store.dispatch('changeIsRe', this.isRe);
                        this.$router.push({
                            name: 'reviewPaper',
                            params: {
                                isRoute: 1
                            }
                        });
                    }
                }).catch(err => {
                    this.$message.warning("服务器维护！");
                })
            },
            downloadAnnex(row){
                this.$message("附件下载");
                console.log(row);
                this.$api.unfinished.downloadAnnexFile({paperId:""+this.paperId} ).then(res => {
                    //   方法二
                    console.log(res)
                    console.log(typeof res.data)

                    let blob = new Blob([res.data], {type: 'application/octet-stream;charset=utf-8'});
                    console.log(blob,5)
                    let link = document.createElement("a");
                    let url = window.url || window.webkitURL;
                    link.href = url.createObjectURL(blob);
                    //重命名文件
                    link.download = this.annexUrl;

                    //释放内存
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    url.revokeObjectURL(link.href);
                }).catch(e => {
                    this.$message("附件下载失败！");
                });

            },
        },
        components: {

        }
    }
</script>

<style scoped>
    .box-card {
        background-color: rgba(255, 255, 255, 0.897);
        border-color: #40a0ff86;
        border-radius: 30px;
    }

    .paperType {
        text-align: center;
        font-size: 3vh;
        font-weight: 500;
        font-family: '黑体';
        padding-top: 1vh;
        padding-bottom: 3vh;

    }

    .paperInfo div {
        margin-bottom: 1vh;
        /* font-family: '楷体'; */
    }

    .paperInfo {
        font-size: 1.6vh;
        height: 17vh;
    }

    .paperCoverBtn {
        border-top: 1px solid rgba(158, 158, 158, 0.329);
        padding-top: 1vh;
        text-align: center;
    }


    .one_tooltip {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .two_tooltip {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -moz-box;
        -moz-line-clamp: 2;
        -moz-box-orient: vertical;
        word-wrap: break-word;
        word-break: break-all;
        white-space: normal;
    }
</style>

<style>
    .box-card .el-card__body {
        padding-top: 2vh 2vh !important;
        height: 33vh !important;
    }

    /* 8vh */
    .box-card .el-card__header {
        padding: 3vh 3vh !important;
        height: 8vh !important;
        font-size: 2vh;
        color: red;
    }
</style>